<template>
  <v-container>

    <Titulo></Titulo>

    <NavBar v-if="loadMounted == true" :step="3" :typePlan="userData.typePlan"></NavBar>

    <br v-if="screen < 800">


    <v-stepper v-model="e1" elevation="0" style="margin-top: -80px;">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Planes
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 12"
            step="5">Datos</v-stepper-step>
        </div>
      </v-stepper-header>

      <v-stepper-items>

        <v-stepper-content step="6">
          <v-form ref="form2" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>

                  <br v-if="screen > 800">
                  <br v-if="screen > 800">

                  <h3 v-if="userData.typePlan == 'Individual'" class="text-center labelSuperior">
                    Antes de continuar, debemos conocer el estado de tu salud. Recuerda que responder
                    con información errónea podria afectar tu cobertura
                  </h3>

                  <h3 v-else class="text-center labelSuperior">
                    Antes de continuar, debemos conocer el estado de tu salud y el de tu familia. Recuerda que responder
                    con información errónea podria afectar tu cobertura
                  </h3>

                  <br>

                  <h3 class="text-center labelTitulo">
                    {{ userData.name }}, por favor responde la Declaración Personal de Salud
                  </h3>

                  <br>

                  <label class="labelQuestion1">
                    Pregunta 1
                  </label>

                  <br>
                  <br>

                  <label v-if="this.userData.typePlan == 'Familiar'" class="labelTexto">
                    ¿Tú, {{ userData.name }} y/o alguno de tus asegurados adicionales <b> están en estudio o
                      conocimiento
                      y/o han sido
                      diagnosticados y/o sometidos a tratamientos médicos </b> por alguna de las siguientes
                    causas?
                  </label>

                  <label v-else class="labelTexto">
                    ¿Tú, {{ userData.name }} <b> estás en estudio o conocimiento y/o has sido diagnosticado(a) y/o
                      sometido(a) a tratamientos médicos</b> por alguna de las siguientes
                    causas?
                  </label>

                  <br><br>
                  <div style="width: 96%;">
                    <label class="labelTexto-negrita">
                      Conforme a la pregunta anterior, declaro estar en conocimiento que de acuerdo con el art. 8 bis de
                      la Ley Nacional del Cáncer N° 21.258, no estoy obligado a declarar enfermedades oncológicas si a
                      la fecha de suscripción de esta propuesta,
                      han transcurrido 5 años o más contados desde la finalización del tratamiento radical sin recaída
                      posterior. Para estos efectos, se entenderá por tratamiento radical lo siguiente: quimioterapia,
                      radioterapia, braquiterapia, hormonoterapia,
                      inmunoterapia y/o tratamientos quirúrgicos realizados con la finalidad de tratar alguna enfermedad
                      oncológica.
                    </label>
                  </div>

                  <br>

                  <label class="labelBeneficioss">

                    • Cáncer o enfermedad neoplásica de cualquier naturaleza (incluidos leucemias y linfomas) <br>
                    • Virus VIH <br>
                    • Virus Hepatitis B o Hepatitis C <br>
                    • Nódulos, masas quistes o tumores, pólipos y/ o lesiones de la piel. <br>
                    • Quimioterapia y/o radioterapia <br>

                  </label>

                  <br>

                  <label class="labelTexto">
                    <b>OJO: </b>Si tuviste un cáncer de cualquier tipo, de acuerdo con la Ley Nacional del Cáncer N°
                    21.258, <b> no tienes que informar sobre enfermedades de cáncer</b> si <u class="labelTexto">han
                      pasado al menos 5 años
                      desde que terminó tu tratamiento radical sin que haya habido una recaída</u>. El tratamiento
                    radical se define como “tratamiento del tipo quimioterapia, radioterapia, cirugía, y otros
                    tratamientos que se usan para tratar el cáncer." <b>Más detalles en este</b> <a :href="nameSite"
                      target="_blank">enlace</a>.
                  </label>

                  <br><br>

                  <v-col>
                    <v-radio-group v-model="userData.questionOne" :rules="questionRules" required row>
                      <div style="display: flex; margin: auto; padding-left: 45%;">
                        <v-radio label="Si" value="Si">
                        </v-radio>
                        <v-radio label="No" value="No">
                        </v-radio>
                      </div>
                    </v-radio-group>
                  </v-col>

                </v-col>
              </div>

              <div class="button-container">
                <v-btn style="background-color: #2D387C; color: white; border-radius: 12px; width: 300px; "
                  @click="validate()">
                  Continuar
                </v-btn>

                <br>
                <br>

                <v-btn text @click="volverAgregar()">
                  Atrás
                </v-btn>

              </div>

            </v-container>
          </v-form>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import CryptoJS from 'crypto-js';

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    loadMounted: false,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      typeIsapre: null,
      region: null,
      commune: null,
      comuneCode: null,
      regionCode: null,
      address: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      stepId: null,
      old: null,
      dates: null,
      salud: null,
      questionOne: null,
      questionTwo: null,
      questionThree: null,
      typePlan: null,
      addressNumber: null,
      addressDepto: null,
      sonExist: false,
      arrayTypesBenef: [],
      oldAux: null,
      day: null,
      month: null,
      year: null,
    },
    valid: false,
    stepperColor: '#4F74E3',
    screen: window.screen.width,
    nameSite: window.location.origin + '/documents/Articule.pdf',
    stepId: null,
    components: { Titulo },
    e1: 6,
    questionRules: [(v) => !!v || "Debe seleccionar una opción"],
  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    const userDataAux = sessionStorage.getItem('userData');

    if (userDataAux) {
      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);
    }

    if (this.screen > 800) {
      this.vista = 'vista2'
    } else {
      this.vista = 'vista'
    }
    this.loadMounted = true;
  },
  methods: {
    back() {
      this.e1--;
    },
    validate() {
      if (this.$refs.form2.validate()) {
        if (this.userData.questionOne == 'Si') {
          this.$router.push({ path: '/og/dps-fallida' })
        } else {

          const userDataLocal = JSON.stringify(this.userData);
          const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
          sessionStorage.setItem('userData', encryptedUserDataLocal);

          this.$router.push({ path: '/og/dps2' });
        }
      }
    },
    volverAgregar() {
      if (this.userData.typePlan == 'Individual') {
        this.$router.push({ path: '/og/planes' });
      } else {
        this.$router.push({ path: '/og/beneficiarios' });
      }
    }
  }
};

</script>

<style lang="scss">
.labelNumLlamar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 50px;
  top: 175px;
  text-align: center;
}

.labelSuperior {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  color: #232323;
  opacity: 0.75;
  height: 103px;
}

.labelTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.labelQuestion1 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #4040CF;
  opacity: 0.75;
  height: 138px;
  top: 375px;
  justify-content: left;
}

.labelTexto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 138px;
  text-align: justify;
}

.labelTexto-negrita {
  font-family: Helvetica;
  font-style: italic;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  color: #232323;
  height: 138px;
  text-align: justify;
  hyphens: auto;
}

.labelBeneficioss {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 150px;
  text-align: justify;
}

.labelExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-top: -5%;
}

.labelSubExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 138px;
  top: 375px;
  text-align: center;
}

.labelNoExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-top: -5%;
}

.labelnoSubExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 138px;
  top: 375px;
  text-align: center;
}

.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-data {
  max-width: 85%;
  align-items: center;
  // display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 150px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

.form-box {
  box-sizing: border-box;
  max-width: 800px;
  background: #F3F3F3;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  border-radius: 6px;
}

.v-input .v-label {
  font-size: 12px;
  color: #8E8E8E;
}

h1 {
  font-weight: 500;
  text-align: center;
  color: #464e71;
  margin-bottom: 2rem;
}

h2 {
  font-weight: 500;
  color: #464e71;
  margin-bottom: 1rem;
}

p {
  text-align: center;
}

.back-container {
  width: 100%;
  align-items: flex-start;

  .back-button {


    &:hover {
      opacity: 1;
    }
  }
}

.button-container {
  text-align: center;
}

.forecast-selector {
  text-transform: uppercase;
  font-weight: 600;
  color: #464e71;
}

.v-btn__content {
  padding: 0.75rem;
}

.v-messages__message {
  padding-bottom: 10%;
  text-align: center;
  margin: auto;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div div.col.vista div.col div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message.message-transition-enter-to {
  padding-bottom: 10%;
  text-align: center;
  min-width: 100px;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.col div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center !important;
}
</style>