<template>
  <div class=" text-center footer">
    <label class="cobertura">El riesgo de este seguro es cubierto por
    </label>
    <div class="text-center">
      <br>
      <v-img contain max-height="70px" :src="LogoSB" max-width="400" style=" margin: auto; " />
    </div>
  </div>
</template>

<script>
import LogoSB from "@/assets/images/LogoFooter3.svg";

export default {
  name: "Footer",
  mounted() {
  },
  data: () => ({
    LogoSB
  }),
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.cobertura {
  font-family: Helvetica;
  font-style: normal;
  text-align: center;
  font-size: 15px;
  font-weight: 550;
  color: #232323;
  line-height: 16px;
  opacity: 0.75;
}

@media only screen and (max-width: 767px) {
  .cobertura {
    font-family: Helvetica;
    font-style: normal;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #232323;
    line-height: 13px;
    opacity: 0.75;
  }
}

.footer {
  height: 120px;
}</style>