<template>
  <v-container>

    <Titulo></Titulo>

    <NavBar :step="4"></NavBar>

    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0" style="margin-top: -80px;">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>
                  <br>

                  <h3 class="text-center labelTitulo">Ingresa los datos de tu familia</h3>

                  <h4 class="text-center labelSubTitulo">
                    Como elegiste un plan familiar, debes agregar la información personal de tu grupo familiar. Es
                    importante completarlo correctamente para no afectar la cobertura.
                  </h4>

                  <br>
                  <br>

                  <div v-for="(benef, index) in benefData" :key="index">

                    <v-expansion-panels :value="benef.panel" class="labelPanel">

                      <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">

                        <v-expansion-panel-header style="height: 48px;">

                          <label>{{ benef.tipo }}</label>

                          <v-icon v-if="!checkImage(benef, index) || benef.validRut == ''"
                            color="red">mdi-alert-circle</v-icon>

                          <v-img
                            v-if="!benef.name == false && !benef.surname == false && benef.validRut == true && !benef.date == false"
                            contain :src="ticket" @load="allImagesPresent = checkAllImagesPresent()" max-width="18px"
                            max-height="18px" />

                        </v-expansion-panel-header>

                        <v-expansion-panel-content>


                          <div class="form-box">

                            <br>

                            <h4 class="text-center labelEtiqueta">
                              Nombre
                            </h4>

                            <v-text-field class="labelsEntrada" v-model="benef.name" :rules="nameRules" label="Nombre"
                              maxlength="51" style="margin: auto;" required filled solo></v-text-field>

                            <br>
                            <br>

                            <h4 class="text-center labelEtiqueta">
                              Apellido
                            </h4>

                            <v-text-field class="labelsEntrada" v-model="benef.surname" :rules="surnameRules"
                              maxlength="51" label="Apellido" required filled solo style="margin: auto;">
                            </v-text-field>

                            <br>
                            <br>

                            <h4 class="text-center labelEtiqueta">
                              RUT
                            </h4>

                            <v-text-field class="labelsEntrada" v-model.lazy="benef.newRut" :rules="rutRules" label=""
                              required filled solo style="margin: auto;" @input="changeRut(index)"></v-text-field>

                            <br>
                            <br>

                            <h4 class="text-center labelEtiquetaFecha">
                              Fecha de Nacimiento
                            </h4>

                            <div>
                              <v-row>
                                <v-col>
                                  <v-select class="labelDate" :items="benef.months" v-model="benef.month"
                                    :rules="monthRules" :loading="loadingMonths" v-on:change="getDays(index)"
                                    item-text="name" label="Mes" return-object required filled solo>
                                  </v-select>
                                </v-col>

                                <br v-if="screen < 800">

                                <v-col>
                                  <v-select class="labelDate" :items="benef.days" v-model="benef.day" :rules="dayRules"
                                    :loading="loadingDays" :disabled="loadingDays || benef.month === null"
                                    item-text="name" label="Día" return-object required filled solo
                                    @change="setDate(index)">
                                  </v-select>
                                </v-col>

                                <br v-if="screen < 800">

                                <v-col>
                                  <v-select class="labelDate" value="2022" :items="benef.years" v-model="benef.year"
                                    :rules="yearRules" item-text="name" label="Año" return-object required filled solo
                                    @change="setDate(index)">
                                  </v-select>
                                </v-col>

                                <br v-if="screen < 800">

                              </v-row>
                            </div>

                            <br>

                            <div class="button-container">
                              <v-btn class="botonGuardar" style="background-color: #2D387C; color: white;"
                                @click="save(index)">
                                Guardar
                              </v-btn>
                            </div>

                            <br>

                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <br>
                    </v-expansion-panels>

                  </div>

                  <br>

                  <div class="button-container">
                    <v-btn class="botonAgregar" style="background-color: #4F74E3; color: white;"
                      @click="addBeneficiary()">
                      AGREGAR OTRO FAMILIAR
                    </v-btn>
                    <br>
                    <br>
                    <br>
                  </div>

                  <div class="button-container">
                    <v-btn class="botonContinuar" style="background-color: #2D387C; color: white;" @click="validate()">
                      Continuar
                    </v-btn>

                    <br>
                    <br>

                    <v-btn text @click="back()">
                      Atrás
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import RutUtils from "@/utils/rut.utils.js";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";

const moment = require('moment');
import swal from 'sweetalert';
import CryptoJS from 'crypto-js';

import NavBar from "@/components/NavBar.vue";
import ticket from "@/assets/images/ticket.png";
import Titulo from "../components/Titulo.vue";
import ShieldService from "../services/shield.service";

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      typeIsapre: null,
      region: null,
      commune: null,
      comuneCode: null,
      regionCode: null,
      address: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      stepId: null,
      old: null,
      dates: null,
      salud: null,
      questionOne: null,
      questionTwo: null,
      questionThree: null,
      typePlan: null,
      addressNumber: null,
      addressDepto: null,
      sonExist: false,
      arrayTypesBenef: [],
      oldAux: null,
      day: null,
      month: null,
      year: null,
    },
    benefData: [
      {
        validRut: false,
        tipo: null,
        edad: null,
        name: '',
        surname: '',
        rut: '',
        days: ['Selecciona el mes'],
        months: null,
        years: null,
        date: null,
        newRut: '',
        panel: 0,
        year: null,
        month: null,
        day: null,
      }
    ],
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    planData: {
      planOutDesc: null,
      setDesc: false,
      valueDesc: 0,
      couponId: null,
      plan: null,
    },
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    storedDate: null,
    allImagesPresent: false,
    ticket,
    loadingDays: false,
    screen: window.screen.width,
    stepperColor: '#4F74E3',
    stepId: null,
    options: [
      { text: "Hija", value: "opcion1" },
      { text: "Hijo", value: "opcion2" },
      { text: "Cónyuge", value: "opcion3" },
    ],
    selectedOption: "",
    fieldsToShow: [],
    showTextField: false,
    e1: 4,
    month: null,
    day: null,
    year: null,
    monthsArray: null,
    daysArray: null,
    yearsArray: null,
    valid: false,
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => (v && v.length >= 11 && v.length <= 12) || "Rut no válido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    nameRules: [
      // (v) => !!v || "El nombre es requerido",
      (v) => !!v.trim() || "El nombre es requerido",
      // (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    surnameRules: [
      // (v) => !!v || "El apellido es requerido",
      (v) => !!v.trim() || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s-]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    dayRules: [
      (v) => !!v || "La día es requerido",
    ],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ],
    newRut: "",
  }),
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    const userDataAux = sessionStorage.getItem('userData');
    const benefDataAux = sessionStorage.getItem('benefData');
    const servicesDataAux = sessionStorage.getItem('servicesData');

    const query = this.$route.query;

    if (query.stepID) {
      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';
      this.planData = abandoned.planData;
      this.benefData = abandoned.benefData;

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const planDataLocal = JSON.stringify(this.planData);
      const benefDataLocal = JSON.stringify(this.benefData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      const encryptedBenefDataLocal = CryptoJS.AES.encrypt(benefDataLocal, this.secretKey).toString();

      sessionStorage.setItem('userData', encryptedUserDataLocal);
      sessionStorage.setItem('servicesData', encryptedServicesDataLocal);
      sessionStorage.setItem('planData', encryptedPlanDataLocal);
      sessionStorage.setItem('benefData', encryptedBenefDataLocal);

      this.verifiRut();

    } else if (benefDataAux) {
      const bytesBenefData = CryptoJS.AES.decrypt(benefDataAux, this.secretKey);
      const decryptedDataBenef = bytesBenefData.toString(CryptoJS.enc.Utf8);
      this.benefData = JSON.parse(decryptedDataBenef);
      console.log("benefData: ", this.benefData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      console.log("servicesData: ", this.servicesData);

      this.verifiRut();

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      console.log("userData: ", this.userData);

      this.setArrays();
    }

    if (this.screen > 800) {
      this.vista = "vista2";
    } else {
      this.vista = "vista";
    }
  },
  methods: {
    async setArrays() {

      const monthsArray = await dateService.getMonths();
      const yearsArray = await yearService.getYears();

      this.benefData.forEach((benef, index) => {
        benef.months = monthsArray;
        benef.years = yearsArray;
        if (benef.date) {
          this.getDaysMounted(index);
        }
      });
    },
    setDate(index) {
      const fechaActual = new moment(new Date());
      if (this.benefData[index].month && this.benefData[index].day && this.benefData[index].year) {
        var date = '' + this.benefData[index].month.number + '-' + this.benefData[index].day.id + '-' + this.benefData[index].year.name;
        var dateNacimiento = moment(new Date(date));
        if (dateNacimiento.isSameOrBefore(fechaActual, 'day')) {
          var timesDiff = fechaActual.diff(dateNacimiento, 'years');
          if (timesDiff == this.benefData[index].edad.name) {
            this.benefData[index].date = '' + this.benefData[index].day.id + '-' + this.benefData[index].month.number + '-' + this.benefData[index].year.name;
          }
          else {
            this.benefData[index].date = false

            swal({
              title: "Atención!",
              text: "La fecha de nacimiento no coincide con la edad de beneficiario.",
              icon: "info",
              button: "De acuerdo",
            });
          }
        }
      }
      this.changeBisiesto(index);
    },
    async changeBisiesto(index) {
      if (this.benefData[index].month && this.benefData[index].year && this.benefData[index].days && this.benefData[index].month.name == 'Febrero' && !this.isBisiesto(this.benefData[index].year.id) && this.benefData[index].days.length == 29) {
        this.benefData[index].date = null;
        this.benefData[index].days = await dateService.getDays(
          this.benefData[index].month.name, false
        );
        this.daysArray = this.benefData[index].days
      } else if (this.benefData[index].month && this.benefData[index].year && this.benefData[index].days && this.benefData[index].month.name == 'Febrero' && this.isBisiesto(this.benefData[index].year.id) && this.benefData[index].days.length == 28) {
        this.benefData[index].days = await dateService.getDays(
          this.benefData[index].month.name, true
        );
        this.daysArray = this.benefData[index].days
      } else {
        this.benefData[index].days = await dateService.getDays(
          this.benefData[index].month.name, null
        );
        this.daysArray = this.benefData[index].days
      }
    },
    isBisiesto(anio) {
      return (anio % 4 === 0 && anio % 100 !== 0) || (anio % 400 === 0);
    },
    save(index) {
      if (this.benefData[index].name.trim() && this.benefData[index].surname.trim() && this.benefData[index].day && this.benefData[index].month && this.benefData[index].year) {
        if (this.benefData[index].panel == 0) {
          this.benefData[index].panel = 1
        } else if (this.benefData[index].panel == 1) {
          this.benefData[index].panel = 0
        }
      } else {
        swal({
          title: "Atención!",
          text: "Debe ingresar todos los campos requeridos",
          icon: "info",
          button: "De acuerdo",
        });
      }
    },
    addBeneficiary() {
      this.$router.push({ path: '/og/beneficiarios' })
    },
    async getDays(index) {
      if (this.benefData[index].month) {
        this.loadingDays = true;
        this.benefData[index].day = null;
        this.benefData[index].days = await dateService.getDays(this.benefData[index].month.name);
        this.changeBisiesto(index);
        this.loadingDays = false;
      }
    },
    async getDaysMounted(index) {
      if (this.benefData[index].month) {
        this.loadingDays = true;
        this.benefData[index].days = await dateService.getDays(this.benefData[index].month.name);
        this.changeBisiesto(index);
        this.loadingDays = false;
      }
    },
    async validate() {
      if (this.$refs.form.validate() && this.allImagesPresent === true) {

        const benefDataLocal = JSON.stringify(this.benefData);
        const encryptedBenefDataLocal = CryptoJS.AES.encrypt(benefDataLocal, this.secretKey).toString();
        sessionStorage.setItem('benefData', encryptedBenefDataLocal);

        await ShieldService.newCreateStep(null, this.benefData, null, this.servicesData, 'Datos Beneficiarios');

        if (this.userData.email && this.activeCampaingUse == 'TRUE') {
          const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

          this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

          await ShieldService.updateContactActiveCampain(this.userData, null, this.servicesData, 7);

          const servicesDataLocal = JSON.stringify(this.servicesData);
          const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
          sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

        }

        this.$router.push({ path: '/og/datos-contratante' })

      } else {
        swal({
          title: "Atención!",
          text: "Debe ingresar y validar todos los campos requeridos",
          icon: "info",
          button: "De acuerdo",
        });
        this.allImagesPresent = false
      }
    },
    checkAllImagesPresent() {
      return this.benefData.every(benef => benef.name && benef.surname && benef.rut && benef.date);
    },
    checkImage(benef, index) {
      if (RutUtils.validateRut(benef.rut) && benef.rut) {
        this.benefData[index].validRut = true
      } else {
        this.benefData[index].validRut = false
      }
      return !!(benef.name && benef.surname && RutUtils.validateRut(benef.rut) && benef.date);
    },
    verifiRut() {
      const cleanRut = RutUtils.cleanRut(this.userData.rut);
      let isRutDuplicated = [false, -1];

      for (let idx = 0; idx < this.benefData.length; idx++) {
        const beneficiary = this.benefData[idx];
        if (beneficiary.rut && cleanRut && RutUtils.cleanRut(beneficiary.rut) === cleanRut) {
          isRutDuplicated = [true, idx];
          break;
        }
      }

      if (isRutDuplicated[0]) {
        this.benefData[isRutDuplicated[1]].rut = '';
        this.benefData[isRutDuplicated[1]].newRut = '';
        swal({
          title: "Atención!",
          text: "Tu RUT no puede coincidir con el del contratante",
          icon: "info",
          button: "De acuerdo",
        });
      }
    },
    changeRut(index) {

      const cleanRut = RutUtils.cleanRut(this.benefData[index].newRut);
      const formattedRut = RutUtils.formatRut(this.benefData[index].newRut);

      const isRutDuplicated = this.benefData.some((beneficiary, idx) => {
        if (beneficiary.rut.trim() !== '' && this.benefData[index].newRut.trim() !== '') {
          return idx !== index && RutUtils.cleanRut(beneficiary.rut) === cleanRut;
        } else {
          return false;
        }
      });

      if (isRutDuplicated) {
        swal({
          title: "Atención!",
          text: "Tu RUT no puede coincidir con el de otro beneficiario!",
          icon: "info",
          button: "De acuerdo",
        });
      } else {
        this.benefData[index].rut = formattedRut;
        this.benefData[index].newRut = formattedRut;
      }

      if (this.userData.rut) {
        this.verifiRut();
      }

    },
    back() {
      this.$router.push({ path: '/og/dps3' })
    },
  },
};
</script>

<style lang="scss">
.labelTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-top: -5%;
}

.labelSubTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelPanel {
  margin-bottom: 5%;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  margin: auto;
  max-width: 98%;
  margin-top: 20px;
}

.labelsEntrada {
  box-sizing: border-box;
  height: 57px;
  max-width: 85%;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelDate {
  max-width: 135px;
  height: 57px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.botonAgregar {
  border-radius: 12px;
  width: 214px;
  height: 39px;
  font-size: 13px;
}

.labelEtiqueta {
  font-family: Helvetica;
  font-style: normal;
  width: 147px;
  height: 25px;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #5a5a5a;
  letter-spacing: -0.01em;
}

@media only screen and (max-width: 767px) {
  .labelEtiqueta {
    padding-right: 17%;
    font-family: Helvetica;
    font-style: normal;
    height: 25px;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #5a5a5a;
    letter-spacing: -0.01em;
  }
}

.labelEtiquetaFecha {
  font-family: Helvetica;
  font-style: normal;
  width: 147px;
  height: 25px;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #5a5a5a;
  letter-spacing: -0.01em;
  margin-left: 25%;
}

@media only screen and (max-width: 767px) {
  .labelEtiquetaFecha {
    font-family: Helvetica;
    font-style: normal;
    width: 147px;
    height: 25px;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #5a5a5a;
    letter-spacing: -0.01em;
    margin-left: 18%;
  }
}

.botonContinuar {
  border-radius: 12px;
  width: 310px;
  height: 100%;
}

.botonGuardar {
  border-radius: 12px;
  width: 250px;
  height: 100%;
}

.vista {
  // max-width: 1000px;
  max-width: 100% !important;
}

.vista2 {
  min-width: 500px;
  max-width: 70% !important;
}

.step-data {
  max-width: 20%;
  align-items: center;
  // display: flex;

  justify-content: center;

  .v-select {
    max-width: 120px;
  }
}

.col {
  max-width: 70%;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

.form-box {
  box-sizing: border-box;
  background: #f3f3f3;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  border-radius: 6px;
  margin: auto;
}

.v-input .v-label {
  font-size: 12px;
  color: #8e8e8e;
}

// Escritorio
html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel.v-expansion-panel--active.v-item--active div.v-expansion-panel-content div.v-expansion-panel-content__wrap div.form-box div div.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 1px;
}

// Movil
html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel.v-expansion-panel--active.v-item--active div.v-expansion-panel-content div.v-expansion-panel-content__wrap div.form-box div div.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 1px;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel.v-expansion-panel--active.v-item--active div.v-expansion-panel-content div.v-expansion-panel-content__wrap div.form-box div div.row div.col {
  max-width: 68%;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel button.v-expansion-panel-header i.v-icon.notranslate.mdi.mdi-alert-circle.theme--light.red--text {
  justify-content: end;
}
</style>
